import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Nav, NavItem, NavLink, UncontrolledTooltip, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from "reactstrap";
import classnames from "classnames";
import { connect, useSelector, useDispatch } from "react-redux";
import { getLoggedInUser, getLoggedInUserAdmin } from '../../helpers/authUtils';
import { setActiveTab, initPage } from "../../redux/actions";
import config_empresa from "../../config_empresa"
import { AiOutlineFileSearch } from "react-icons/ai"
import { FaUserAltSlash, FaUser } from "react-icons/fa";
import ModalVerComo from "../../components/SelectAtendente"

//Import Images
import logo from "../../assets/images/juriszap/logo-2.png"
import defaultAvatar from "../../assets/images/users/user.png"
import logoZapResponder from "../../assets/images/zapresponder/logo.png"
import { APIClient, setAuthorization } from "../../helpers/apiClient"
import { loginUser, updateStatus } from '../../redux/actions';
import { Avatar } from '@mui/material';
//i18n
import i18n from '../../i18n';

// falgs
const LAYOUT = {
    "zapresponder": { logo: logoZapResponder, name: "Zap Responder" },
    "multdesk": { logo: logo, name: "Multdesk" }
}

const api = new APIClient()

function LeftSidebarMenu(props) {

    const dispatch = useDispatch()

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpen2, setDropdownOpen2] = useState(false);
    const [dropdownOpenMobile, setDropdownOpenMobile] = useState(false);
    const [openModalVerComo, SetModalVerComo] = useState(false)
    const [atendentes, setAtendente] = useState([])
    const { totalFila } = useSelector(state => state.Chat)
    const { isAdmin } = useSelector(state => state.Auth)
    const { status } = useSelector(state => state.Auth.user)

    const { photo, token } = getLoggedInUser()
    const userAdmin = getLoggedInUserAdmin()
    setAuthorization(token)

    const toggle = () => setDropdownOpen(!dropdownOpen);
    const toggle2 = () => setDropdownOpen2(!dropdownOpen2);
    const toggleMobile = () => setDropdownOpenMobile(!dropdownOpenMobile);
    const toggleVerComo = () => SetModalVerComo(!openModalVerComo)

    const toggleTab = tab => {
        props.setActiveTab(tab)
    }

    const activeTab = props.activeTab;

    useEffect(() => {
        if (isAdmin) {
            api.get("atendente/findByAdmin")
                .then(success => {
                    setAtendente(success)
                })
                .catch(err => err)
        }
    }, [isAdmin])

    const setUsuario = (atendente) => {
        dispatch(loginUser(null, null, null, true, atendente))
    }

    const handleChangeStatus = () => {
        let value = status == "Online" ? "Offline" : "Online"
        api.update("atendente", { status: value }).then(success => {
            dispatch(updateStatus(value))
        }).catch(err => console.log(err))
    }

    return (
        <React.Fragment>
            <div className="side-menu flex-lg-column me-lg-1">
                {/* LOGO */}
                <div className="navbar-brand-box">
                    <Link to="/" className="logo logo-dark" onClick={() => dispatch(initPage())}>
                        <span className="logo-sm">
                            <img src={LAYOUT[config_empresa.EMPRESA].logo} alt="logo" height="30" />
                        </span>
                    </Link>

                    <Link to="/" className="logo logo-light" onClick={() => dispatch(initPage())}>
                        <span className="logo-sm">
                            <img src={LAYOUT[config_empresa.EMPRESA].logo} alt="logo" height="30" />
                        </span>
                    </Link>
                </div>
                {/* end navbar-brand-box  */}

                {/* Start side-menu nav */}
                <div className="flex-lg-column my-auto">
                    <Nav pills className="side-menu-nav justify-content-center" role="tablist">
                        <NavItem id="Chats">
                            <NavLink id="pills-chat-tab" className={classnames({ active: activeTab === 'chat' })} onClick={() => { toggleTab('chat'); }}>
                                <i className="ri-message-3-line ri-mx"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Chats" placement="top">
                            Chats
                        </UncontrolledTooltip>
                        {isAdmin &&
                            <React.Fragment>
                                <NavItem id="etiquetas-index">
                                    <NavLink id="etiquetas-nav" className={classnames({ active: activeTab === 'etiquetas-tab' })} onClick={() => { toggleTab('etiquetas-tab'); }}>
                                        <i className="ri-price-tag-3-line ri-mx"></i>
                                    </NavLink>
                                </NavItem>
                                <UncontrolledTooltip target="etiquetas-index" placement="top">
                                    Etiquetas
                                </UncontrolledTooltip>
                            </React.Fragment>
                        }

                        <NavItem id="hist-message-tab">
                            <NavLink id="hist-message" className={classnames({ active: activeTab === 'hist-message' })} onClick={() => {
                                toggleTab('hist-message');
                            }}>
                               <i className="ri-article-line ri-mx"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="hist-message-tab" placement="top">
                            Históricos
                        </UncontrolledTooltip>


                        <NavItem id="Fila">
                            <NavLink id="pills-groups-tab" className={classnames({ active: activeTab === 'group' })} onClick={() => { toggleTab('group'); }}>
                                <span className="badge badge-soft-danger rounded-pill" style={{
                                    position: "absolute",
                                    marginTop: "20px",
                                    marginLeft: "20px",
                                    fontSize: "10px"
                                }}>{totalFila}</span>
                                <i className="ri-group-line ri-mx">
                                </i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Fila" placement="top">
                            Fila
                        </UncontrolledTooltip>
                        <NavItem id="Contacts">
                            <NavLink id="pills-contacts-tab" className={classnames({ active: activeTab === 'contacts' })} onClick={() => { toggleTab('contacts'); }}>
                                <i className="ri-contacts-book-3-line ri-mx"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="Contacts" placement="top">
                            Contatos
                        </UncontrolledTooltip>
                        <NavItem id="equipe">
                            <NavLink id="pills-contacts-tab" className={classnames({ active: activeTab === 'equipe' })} onClick={() => { toggleTab('equipe'); }}>
                                <i className="ri-team-line ri-mx"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="equipe" placement="top">
                            Equipe
                        </UncontrolledTooltip>

                        <NavItem id="fast-message-tab">
                            <NavLink id="fast-message" className={classnames({ active: activeTab === 'fast-message' })} onClick={() => { toggleTab('fast-message'); }}>
                                <i className="ri-mail-send-fill ri-mx"></i>
                            </NavLink>
                        </NavItem>
                        <UncontrolledTooltip target="fast-message-tab" placement="top">
                            Mensagens Rápidas
                        </UncontrolledTooltip>

                        <Dropdown nav isOpen={dropdownOpenMobile} toggle={toggleMobile} className="profile-user-dropdown d-inline-block d-lg-none">
                            <DropdownToggle nav>
                                <Avatar src={photo || defaultAvatar} alt="multdesk" style={{ border: status === "Online" ? '3px solid #ACFF6C' : '3px solid #FF8A8A' }} onError={(event) => event.target.src = defaultAvatar} />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem onClick={handleChangeStatus}>{status} {status === "Online" ? <FaUser className='float-end text-muted' /> : <FaUserAltSlash className='float-end text-muted' />}</DropdownItem>
                                <DropdownItem divider />
                                {isAdmin && !userAdmin && <DropdownItem onClick={toggleVerComo}>Ver como <i class="ri-eye-fill float-end text-muted"></i> </DropdownItem>}
                                <DropdownItem onClick={() => { toggleTab('settings'); }}>Perfil <i class="ri-user-line float-end text-muted"></i> </DropdownItem>
                                <DropdownItem href="/logout">Sair <i className="ri-logout-circle-r-line float-end text-muted"></i></DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                </div>
                {/* end side-menu nav */}

                <div className="flex-lg-column d-none d-lg-block">
                    <Nav className="side-menu-nav justify-content-center">
                        <Dropdown nav isOpen={dropdownOpen} className="nav-item btn-group dropup profile-user-dropdown" toggle={toggle}>
                            <DropdownToggle className="nav-link" tag="a">
                                <Avatar src={photo || defaultAvatar} alt="multdesk" style={{ border: status === "Online" ? '3px solid #ACFF6C' : '3px solid #FF8A8A', marginLeft: '6px' }} onError={(event) => event.target.src = defaultAvatar} />
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={handleChangeStatus}>{status} {status === "Online" ? <FaUser className='float-end text-muted' /> : <FaUserAltSlash className='float-end text-muted' />}</DropdownItem>
                                <DropdownItem divider />
                                {isAdmin && !userAdmin && <DropdownItem onClick={toggleVerComo}>Ver como <i class="ri-eye-fill float-end text-muted"></i> </DropdownItem>}
                                <DropdownItem onClick={() => { toggleTab('settings'); }}>Perfil <i class="ri-user-line float-end text-muted"></i> </DropdownItem>
                                <DropdownItem href="/logout">Sair <i className="ri-logout-circle-r-line float-end text-muted"></i></DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                </div>
                <ModalVerComo
                    Callmodal={openModalVerComo}
                    atendentes={atendentes}
                    toggleCallModal={toggleVerComo}
                    setSelect={setUsuario}
                />
                {/* Side menu user */}
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    return {
        ...state.Layout
    };
};

export default connect(mapStatetoProps, {
    setActiveTab
})(LeftSidebarMenu);