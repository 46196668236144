const config = {
    API_URL: "https://api.juriszap.com/",
    URL_SOCKET: "https://websocket.juriszap.com",
    // API_URL: "https://hmlapi.multdesk.com.br/",
    // URL_SOCKET: "https://hmlwebsocket.multdesk.com.br",
    // API_URL: "https://api.zapresponder.com.br/",
    // URL_SOCKET: "https://websocket.zapresponder.com.br",
    // API_URL: "http://localhost:3001/",
    // URL_SOCKET: "http://localhost:3002/",
    STORAGE_URL: "https://storage.juriszap.com/",
    APP_URL: "https://juriszap.com",
    AUTH_BACKEND: 'Dummy'
}

export default config
